/**
 * Created by thiago on 15/10/16.
 */
angular.module('appSite').controller('solicitarDoacaoCtrl',
    ["$scope", "$http", "$location", "solicitacaoDoacaoApiService", "tipoSanguineoApiService", "hemocentroUnidadeApiService", "hemocentroApiService", "$uibModal", function ($scope, $http, $location, solicitacaoDoacaoApiService, tipoSanguineoApiService,
              hemocentroUnidadeApiService, hemocentroApiService, $uibModal) {
        // Tratamento modal
        $scope.open = function (size, parentSelector) {
            var parentElem = parentSelector ?
                angular.element($document[0].querySelector('.modal-demo ' + parentSelector)) : undefined;
            $scope.modalInstance = $uibModal.open({
                animation: true,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: 'modalSucessoSolicitacao.html',
                controller: 'modalInstanceSolicitacao',
                controllerAs: '$ctrl',
                size: size,
                appendTo: parentElem,
                resolve: {}
            });
        };

        $scope.solicitacaoDoacao = {};
        $scope.hemocentroUnidades = [];
        $scope.delay = 0;
        $scope.minDuration = 0;
        $scope.message = 'Notificando doadores...';
        $scope.backdrop = true;
        $scope.promise = null;
        // Traducao multselect
        $scope.translation = {
            selectAll: 'Marcar todas',
            selectNone: 'Desmarcar todas',
            reset: 'reset',
            search: 'Pesquisar...',
            nothingSelected: 'Nenhuma unidade selecionada...'
        };

        // Busca os tipos sanguineos
        getTiposSanguineos = function () {
            tipoSanguineoApiService.getTipoSanguineo().success(function (data) {
                $scope.tipos_sanguineos = data;
            })
        };

        // Salva a solicitacao de doacao
        $scope.salvarSolicitacaoDoacao = function (solicitacaoDoacao) {
            solicitacaoDoacao.qtd_dias_visivel = $scope.value
            if (solicitacaoDoacao['locais']) {
                if (solicitacaoDoacao.locais.length < 1) {
                    delete solicitacaoDoacao.locais;
                }
            }
            if (!solicitacaoDoacao['email']) {
                solicitacaoDoacao.email = "invalido";
            }
            $scope.promise = solicitacaoDoacaoApiService.saveSolicitacaoDocao(solicitacaoDoacao).
                success(function (data) {
                    $scope.open(); // Abre modal de sucesso
                    delete $scope.solicitacaoDoacao;
                    document.getElementById('solicitacaoDoacao').style.display = "none";
                }).error(function (data) {
                    $scope.erro = data;
                    if (data.local_internacao) {
                        $scope.solicitacaoDoacao.localinternacao.$dirty = true;
                        document.getElementById('motivo').focus();
                        document.getElementById('local-internacao').focus();
                    }
                    if (data.motivo) {
                        $scope.solicitacaoDoacao.motivosolicitacao.$dirty = true;
                        document.getElementById('radio_sangue').focus();
                        document.getElementById('motivo').focus();
                    }
                    if (data.tipo_sanguineo) {
                        document.getElementById('nome-paciente').focus();
                        document.getElementById('tiposanguineo').focus();

                    }
                    if (data.nome_paciente) {
                        $scope.solicitacaoDoacao.paciente.$dirty = true;
                        document.getElementById('radio_sangue').focus();
                        document.getElementById('nome-paciente').focus();
                    }
                    if (data.nome_solicitante) {
                        $scope.solicitacaoDoacao.solicitante.$dirty = true;
                        document.getElementById('radio_sangue').focus();
                        document.getElementById('nome-solicitante').focus();
                    }
                    if (data.email) {
                        $scope.solicitacaoDoacao.emailsolicitante.$dirty = true;
                        document.getElementById('radio_sangue').focus();
                        document.getElementById('email-solicitante').focus();
                        solicitacaoDoacao.email = undefined;
                    }
                    if (data.tipo_doacao) {
                        document.location = "#solicitacaoDoacao";
                        document.getElementById('radio_sangue').focus();
                    }

                });
        };

        // Retorna todos os hemocentros
        getHemocentro = function () {
            hemocentroApiService.getHemocentro().success(function (data) {
                $scope.hemocentros = data
            })
        };

        $scope.hemocentrosPossieis = [];
        $scope.getHemocentros = function (estado) {
            if (!estado){
                delete $scope.solicitacaoDoacao.hemocentro;
                $scope.solicitacaoDoacao.locais = []
            }
            $scope.hemocentrosPossieis = $scope.hemocentros.filter(function (hemocentro) {
                if (hemocentro.estado == estado.sigla) {
                    console.log(hemocentro);
                    return hemocentro
                }
            })
        };

        // Retorna todas as unidades dos hemocentros
        getHemocentroUnidades = function () {
            hemocentroUnidadeApiService.getHemocentroUnidade().success(function (data) {
                $scope.hemocentroUnidades = data
            })
        };
        getHemocentro();
        getHemocentroUnidades();

        $scope.getUnidades = function (hemocentro) {
            $scope.solicitacaoDoacao.locais = [];
            $scope.unidades_possiveis = [];
            if (hemocentro) {
                $scope.listaUnidades = $scope.hemocentroUnidades.filter(function (unidade) {
                    if (hemocentro === unidade.hemocentro) return unidade;
                })
            }
            $scope.listaUnidades.forEach(function (unidade) {
                $scope.unidades_possiveis.push({
                    'hemocentro': unidade.hemocentro,
                    'hemocentro_unidade': unidade.id,
                    nome_unidade: unidade.nome_unidade
                })
            });
        };


        getTiposSanguineos();
        getHemocentro();
        getHemocentroUnidades();

        $scope.checkEstado = function (estado_onde_doa) {
            console.log(estado_onde_doa);
            if (!estado_onde_doa) {
                $uibModal.open({
                    animation: true,
                    ariaLabelledBy: 'modal-title',
                    ariaDescribedBy: 'modal-body',
                    templateUrl: 'modalHemocentroAtencao.html',
                    controller: 'modalInstanceSolicitacao',
                    controllerAs: '$ctrl'

                });
            }
        }
        $scope.value = 10;
        $scope.min = 1;
        $scope.max = 30;
    }]);

angular.module('appSite').controller('modalInstanceSolicitacao', ["$uibModalInstance", "$scope", function ($uibModalInstance, $scope) {
    $scope.ok = function () {
        $uibModalInstance.close();
    };
}]);
