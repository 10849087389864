/**
 * Created by thiago on 20/10/16.
 */

angular.module('appSite').factory('cidadeDoacaoApiService', function ($http, config) {

    var _getCidadeDoacao = function () {
        return $http.get(config.baseUrl + '/api/cidadedoacao/?format=json')
    };
    return {
        getCidadeDoacao: _getCidadeDoacao
    }
});
