/**
 * Created by thiago on 09/11/16.
 */

angular.module('appSite').factory('AuthService', ["$http", "$sessionStorage", "$q", "config", function ($http, $sessionStorage, $q, config) {
  var _getToken = function () {
    return $sessionStorage.token;
  };

  var _setToken = function (token) {
    $sessionStorage.token = token;
  };

  var _setUser = function (user_id) {
    $sessionStorage.user_id = user_id;
  };

  var _getUser = function () {
    return $sessionStorage.user_id;
  };

  var _signin = function (data) {
    return $http.post(config.baseUrl + '/api-token-auth/', data)
  };

  var _signup = function (data) {

  };

  var _logout = function () {
    delete $sessionStorage.token;
    $q.when();
  };

  return {
    getToken: _getToken,
    setToken: _setToken,
    signin: _signin,
    logout: _logout,
    signup: _signup,
    setUser: _setUser,
    getUser: _getUser
  }
}]);