/**
 * Created by thiago on 21/11/16.
 */
angular.module('appSite').controller('depoimentoCtrl', ["$scope", "depoimentoApiService", "$uibModal", function ($scope, depoimentoApiService, $uibModal) {
    $scope.depoimento = {};
    $scope.depoimentos = [];
    $scope.depoimentos_doacao_recebida = [];
    $scope.depoimentos_doacao_feita = [];
    $scope.error = {};
    $scope.jsonFile = null;
    // Tratamento modal
    $scope.open = function (size, parentSelector) {
        var parentElem = parentSelector ?
            angular.element($document[0].querySelector('.modal-demo ' + parentSelector)) : undefined;
        $scope.modalInstance = $uibModal.open({
            animation: true,
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl: 'modalSucessoDepoimento.html',
            controller: 'modalInstanceDepoimento',
            controllerAs: '$ctrl',
            size: size,
            appendTo: parentElem,
            resolve: {}
        });
    };

    var getDepoimentos = function () {
        depoimentoApiService.getDepoimentos().success(function (data) {
            $scope.depoimentos = data;
            $scope.depoimentos.forEach(function (depoimento) {
                if (depoimento.tipo_depoimento == 'R') {
                    $scope.depoimentos_doacao_recebida.push(depoimento)
                } else {
                    $scope.depoimentos_doacao_feita.push(depoimento)
                }
            });
        })
    };
    getDepoimentos();

    $scope.openFile = function (file) {
        $scope.jsonFile = file.files[0];
        var fileReader = new FileReader();

        fileReader.onload = function () {
            $scope.fileContent = fileReader.result;
            $scope.$apply();
        };

        fileReader.readAsDataURL($scope.jsonFile);
    };

    function getBase64Image(img) {
        // Create an empty canvas element
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;

        // Copy the image contents to the canvas
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);

        // Get the data-URL formatted image
        // Firefox supports PNG and JPEG. You could check img.src to
        // guess the original format, but be aware the using "image/jpg"
        // will re-encode the image.
        var dataURL = canvas.toDataURL("image/png");

        return dataURL;
    }

    $scope.salvarDepoimento = function (depoimento) {
        $scope.depoimento.foto = $scope.fileContent;
        if (!$scope.depoimento.foto){
            $scope.depoimento.foto = getBase64Image(angular.element(sem_foto)[0]);
        }
        depoimentoApiService.salveDepoimento(depoimento).success(function (data) {
            delete $scope.depoimento;
            document.getElementById('formDepoimento').style.display = "none";
            $scope.open();
        }).error(function (data) {
            $scope.erro = data;
        });
    }
}]);

angular.module('appSite').controller('modalInstanceDepoimento', ["$uibModalInstance", "$scope", function ($uibModalInstance, $scope) {
    $scope.ok = function () {
        $uibModalInstance.close();
    };
}]);