/**
 * Created by thiago on 05/11/16.
 */
angular.module('appSite').controller('loginCtrl',
["$scope", "AuthService", "lembrarSenhaApiService", "$uibModal", "$rootScope", function ($scope, AuthService, lembrarSenhaApiService, $uibModal, $rootScope) {

    $scope.depoimento = {};
    $scope.depoimentos = [];
    $scope.depoimentos_doacao_recebida = [];
    $scope.depoimentos_doacao_feita = [];
    $scope.error = {};    
    $scope.jsonFile = null;
    // Tratamento modal
    $scope.open = function (size, parentSelector) {
        var parentElem = parentSelector ?
            angular.element($document[0].querySelector('.modal-demo ' + parentSelector)) : undefined;
        $scope.modalInstance = $uibModal.open({
            animation: true,
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl: 'modalSucessoRecuperarSenha.html',
            controller: 'modalInstanceRecuperarSenha',
            controllerAs: '$ctrl',
            size: size,
            appendTo: parentElem,
            resolve: {}
        });
    };


    $scope.erro = {};
    $scope.loginPopover = {
        content: 'Efetue o login',
        templateUrl: 'myPopoverTemplate.html',
        title: 'Login'
    };

     // Logar no app.js pra poder logar automaticamente ao fazer cadastro

    $scope.logout = function () {
        AuthService.logout();
        window.location.assign('index.html')
    };

    $scope.equeciSenha = function () {
        $scope.formEsqueci = true
    };

    $scope.voltarLogin = function () {
        $scope.formEsqueci = false
    };

    $scope.recuperarSenha = function (usuario) {
        lembrarSenhaApiService.lembrarSenha(usuario).success(function (data) {
            delete $scope.usuario;
            $scope.open();
            $scope.formEsqueci = false;
        }).error(function (data) {
            $scope.erro = data
        })
    }
}]);

angular.module('appSite').controller('modalInstanceRecuperarSenha', ["$uibModalInstance", "$scope", function ($uibModalInstance, $scope) {
    $scope.ok = function () {
        $uibModalInstance.close();
    };
}]);
