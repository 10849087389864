/**
 * Created by thiago on 20/10/16.
 */
angular.module('appSite').controller('ondeDoarCtrl', ["$scope", "$compile", "NgMap", "cidadeDoacaoApiService", "hemocentroUnidadeApiService", "hemocentroApiService", function ($scope, $compile, NgMap,
                                                               cidadeDoacaoApiService,
                                                               hemocentroUnidadeApiService, hemocentroApiService) {
    $scope.cidadesDoEstadoDoacao = [];
    $scope.setCidadeSelecionadaMapa = function (cidade) {
        $scope.cidadeSelecionadaMapa = cidade;
    };

    $scope.onSelect = function ($item, $model, $label) {
        $scope.cidadeSelecionadaMapa = $model;
        var cidadeId = $item.id;
        var hemocentros = $scope.hemocentroUnidades.filter(function (hemocentro) {
            if (hemocentro.cidade == cidadeId) return hemocentro
        });
        if (hemocentros.length == 1) {
            $scope.zoom = 18;
            $scope.cidadeSelecionadaMapa = hemocentros[0].latitude + "," + hemocentros[0].longitude;
            console.log($scope.cidadeSelecionadaMapa)
        } else {
            $scope.zoom = 11;
        }
    };

    $scope.setMap = function (local, zoom) {
        $scope.cidadeSelecionadaMapa = local;
        $scope.zoom = zoom;

    };
    $scope.setMap('Brasil', 4);
    NgMap.getMap().then(function (map) {
        $scope.map = map;
    });

    $scope.showEndereco = function (event, un) {
        $scope.zoom = 18;
        $scope.unidade = un;
        $scope.map.showInfoWindow('myInfoWindow', this);
    };

    // Retorna todas as unidades dos hemocentros
    getHemocentroUnidades = function () {
        hemocentroUnidadeApiService.getHemocentroUnidade().success(function (data) {
            $scope.hemocentroUnidades = data;
        })
    };


    getCidadeDoacao = function () {
        cidadeDoacaoApiService.getCidadeDoacao().success(function (data) {
            $scope.cidadesDoacao = data
        })
    };

    var getHemocentros = function () {
        hemocentroApiService.getHemocentro().success(function (data) {
            $scope.hemocentros = data;
        })
    };

    $scope.getHemocentroNome = function (hemocentro_id) {
        return $scope.hemocentros.filter(function (hemocentro) {
            if (hemocentro.id == hemocentro_id) return hemocentro
        })[0].nome;
    };

    $scope.getCidadesParaDoacao = function (estado) {
        $scope.cidadeSelecionada = '';
        $scope.setMap(estado.nome, 6);
        $scope.cidadesDoEstadoDoacao = $scope.cidadesDoacao.filter(function (cidade) {
            if (cidade.estado == estado.sigla) {
                return cidade;
            }
        });
    };

    $scope.limparPesquisa = function () {
        $scope.estado = false;
        delete $scope.cidadeSelecionada;
        $scope.cidadesDoEstadoDoacao = [];
        $scope.setMap('Brasil', 4)
    };

    getHemocentros();
    getCidadeDoacao();
    getHemocentroUnidades();
}]);