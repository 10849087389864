/**
 * Created by thiago on 12/11/16.
 */
angular.module('appSite').directive('uiIsEmpty', function(){
    return {
        require: 'ngModel',
        scope: {
            listModel: "=uiIsEmpty"
        },
        link: function (scope, element, attributes, ngModel) {
            ngModel.$validators.uiIsEmpty = function(){
                if (scope.listModel.length < 1) {return true}
            };

            scope.$watch("listModel", function(){
                ngModel.$validate();
            });
        }
    }
});