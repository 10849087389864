/**
 * Created by thiago on 15/10/16.
 */
angular.module('appSite').factory('tipoSanguineoApiService', function ($http, config) {

    var _getTipoSanguineo = function(){
        return $http.get(config.baseUrl + '/api/tiposanguineo/?format=json')
    };

    return {
        getTipoSanguineo: _getTipoSanguineo
    }
});
