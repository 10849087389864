/**
 * Created by thiago on 09/11/16.
 */
angular.module('appSite')
  .factory('AuthInterceptor', ['$q', '$location', '$injector', function ($q, $location, $injector) {
    return {
      request: function (config) {
        config.headers = config.headers || {};
        var authService = $injector.get('AuthService');
        if (authService.getToken()) {
          config.headers['Authorization'] = 'Token ' + authService.getToken();
        }

        return config;
      },

      responseError: function (response) {
        if (response.status === 401 || response.status === 403) {
          $location.path('/');
        }

        return $q.reject(response);
      }
    }
  }])
  .config(function ($httpProvider) {
    $httpProvider.interceptors.push('AuthInterceptor');
  });
