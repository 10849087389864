/**
 * Created by thiago on 02/11/16.
 */

angular.module('appSite').controller('registroDoacaoCtrl',
    ["$scope", "$rootScope", "hemocentroApiService", "cidadeDoacaoApiService", "registroDoacaoApiService", "campanhaApiService", "AuthService", "$uibModal", "$location", "params", function ($scope, $rootScope, hemocentroApiService, cidadeDoacaoApiService,
              registroDoacaoApiService, campanhaApiService, AuthService, $uibModal, $location, params) {

        $scope.registro = {};
        $scope.sub_campanhas = [];
        $scope.singleModel = 1;

        $scope.tipo_doacao = 'S';

        $scope.$watchCollection('tipo_doacao', function () {
            if ($scope.tipo_doacao == 'S') {
                $scope.registro.doou_medula = false
            } else {
                $scope.registro.doou_medula = true
            }
        });

        $scope.estado_onde_doou = false;
        $scope.cidade = '';

        // Campanhas
        var getCampanhas = function () {
            return campanhaApiService.getCampanha().then(function (data) {
                $scope.campanhas = data;
                $scope.campanhas.push({id: '', titulo: 'Não participei de campanha'})
                return true
            });
        };

        getCampanhas();

        // Tratamento modal
        $scope.open = function (size, parentSelector) {
            var parentElem = parentSelector ?
                angular.element($document[0].querySelector('.modal-demo ' + parentSelector)) : undefined;
            $scope.modalInstance = $uibModal.open({
                animation: true,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: 'modalSucessoRegistroDoacao.html',
                controller: 'modalInstanceRegistroDoacao',
                controllerAs: '$ctrl',
                size: size,
                appendTo: parentElem,
                resolve: {'doador': $scope.doador}
            });
        };

        $scope.getHemocentros = function (estado) {
            $scope.getCidadesParaDoacao(estado);
            $scope.hemocentros = angular.copy($rootScope.hemocentros).filter(function (hemocentro) {
                if (hemocentro.estado == estado) {
                    return hemocentro;
                }
            })
        };

        $scope.checkHemocentro = function () {
            if (!$scope.estado_onde_doou) {
                $uibModal.open({
                    animation: true,
                    ariaLabelledBy: 'modal-title',
                    ariaDescribedBy: 'modal-body',
                    templateUrl: 'modalHemocentroAtencao.html',
                    controller: 'modalInstanceAtencao',
                    controllerAs: '$ctrl'

                });
            }
        };
        var getCidades = function () {
            cidadeDoacaoApiService.getCidadeDoacao().success(function (data) {
                $scope.cidadesDoacao = data;
                // Carrega os dados ao clicar em editar doacao em minhas doacoes
                if (params && params.id) {
                    registroDoacaoApiService.getRegistroDoacao(params.id).then(function (data) {
                        $scope.registro = data.data;
                        if ($scope.registro.campanha) {
                            $scope.getSubCampanhas($scope.registro.campanha);
                        }
                        var cidade = $scope.cidadesDoacao.filter(function (cidade) {
                            if (cidade.id == $scope.registro.cidade) {
                                return cidade
                            }
                        });
                        $scope.registro.data_doacao = new Date($scope.registro.data_doacao);
                        $scope.estado_onde_doou = cidade[0].estado;
                        $scope.cidade = cidade[0];
                        if ($scope.registro.doou_medula == false) {
                            $scope.tipo_doacao = 'S'
                        } else {
                            $scope.tipo_doacao = 'M'
                        }
                        $scope.getHemocentros($scope.estado_onde_doou);
                        $scope.getCidadesParaDoacao($scope.estado_onde_doou);
                        $scope.hemocentros.forEach(function (hemocentro) {
                            if (hemocentro.id == $scope.registro.hemocentro) {
                                $scope.registro.hemocentro = hemocentro[0].id
                            }
                        });
                    })
                }
            })
        };

        // Verifica se a campanha informada possui sub campanha
        var checkSubCampanha = function (registro) {
            var valido = true;
            if (registro.campanha) {
                $scope.campanhas.forEach(function (campanha) {
                    console.log(campanha.id);
                    console.log(registro.campanha);
                    if (campanha.id == registro.campanha) {
                        console.log(campanha.sub_campanhas);
                        console.log(registro.sub_campanha);
                        if (campanha.sub_campanhas.length > 0 && !registro.sub_campanha) {
                            valido = false;
                            $uibModal.open({
                                animation: true,
                                ariaLabelledBy: 'modal-title',
                                ariaDescribedBy: 'modal-body',
                                templateUrl: 'modalErroSubCampanha.html',
                                controller: 'modalInstanceRegistroDoacao',
                                controllerAs: '$ctrl'
                            });
                        }
                    }
                })
            }
            return valido;
        };

        $scope.salvarRegistroDoacao = function (registro) {
            registro.doador = AuthService.getUser();
            if (!checkSubCampanha(registro)) {
                return;
            }
            registroDoacaoApiService.saveRegistroDoacao(registro).success(function (data) {
                delete $scope.registro;
                delete $scope.cidade;
                delete $scope.erro;
                if (registro.id) {
                    $uibModal.open({
                        animation: true,
                        ariaLabelledBy: 'modal-title',
                        ariaDescribedBy: 'modal-body',
                        templateUrl: 'modalAlterarRegistroDocao.html',
                        controller: 'modalInstanceRegistroDoacao',
                        controllerAs: '$ctrl',
                    });
                } else {
                    $scope.open(); // Chama modal de feedback
                }
                $location.path('/minhas-doacoes')

            }).error(function (data) {
                $scope.erro = data;
            })
        };

        $scope.onSelect = function ($item, $model, $label) {
            $scope.registro.cidade = $item.id
        };

        $scope.getCidadesParaDoacao = function (estado) {
            $scope.estado_onde_doou = estado;
            $scope.cidadesDoEstadoDoacao = $scope.cidadesDoacao.filter(function (cidade) {
                if (cidade.estado == estado) {
                    return cidade;
                }
            });
        };

        $scope.checkEstado = function (estado_onde_doou) {
            if (!estado_onde_doou) {
                $uibModal.open({
                    animation: true,
                    ariaLabelledBy: 'modal-title',
                    ariaDescribedBy: 'modal-body',
                    templateUrl: 'modalCidadeAtencao.html',
                    controller: 'modalInstanceAtencao',
                    controllerAs: '$ctrl'

                });
            }
        };

        $scope.format = 'ddMMyyyy';
        $scope.format_original = 'yyyy-MM-dd';

        getCidades();

        // Tratando sub campanhas
        $scope.getSubCampanhas = function (campanha_id) {
            getCampanhas().then(function () {
                var campanha = $scope.campanhas.filter(function (campanha) {
                    return campanha.id == campanha_id
                });
                if (campanha.length > 0) {
                    $scope.sub_campanhas = campanha[0].sub_campanhas;
                } else {
                    $scope.sub_campanhas = [];
                }
            });
        }
    }]);


angular.module('appSite').controller('modalInstanceRegistroDoacao',
    ["$uibModalInstance", "$scope", function ($uibModalInstance, $scope) {
        $scope.doador = $scope.$resolve.doador;
        $scope.ok = function () {
            $uibModalInstance.close();
        };
    }]);
