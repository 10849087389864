/**
 * Created by thiago on 15/10/16.
 */
angular.module('appSite').factory('hemocentroUnidadeApiService', function ($http, config) {
    var _getHemocentroUnidade = function () {
        return $http.get(config.baseUrl + '/api/hemocentrounidades/?format=json')
    };
    return {
        getHemocentroUnidade: _getHemocentroUnidade
    }
});