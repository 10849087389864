/**
 * Created by thiago on 19/11/16.
 */
angular.module('appSite').controller('contatoCtrl', ["$scope", "contatoApiService", "$uibModal", function ($scope, contatoApiService,
                                                              $uibModal) {

    // Tratamento modal
    $scope.open = function (size, parentSelector) {
        var parentElem = parentSelector ?
            angular.element($document[0].querySelector('.modal-demo ' + parentSelector)) : undefined;
        $scope.modalInstance = $uibModal.open({
            animation: true,
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl: 'modalSucessoContato.html',
            controller: 'modalInstanceContato',
            controllerAs: '$ctrl',
            size: size,
            appendTo: parentElem,
            resolve: {contato: $scope.contato}
        });
    };

    $scope.salvarContato = function (contato) {
        contatoApiService.salvarContato(contato).success(function (data) {
            $scope.open();
            delete $scope.contato;
        })
    }
}]);

angular.module('appSite').controller('modalInstanceContato', ["$uibModalInstance", "$scope", function ($uibModalInstance, $scope) {
    $scope.contato = $scope.$resolve.contato;
    $scope.ok = function () {
        $uibModalInstance.close();
    };
}]);