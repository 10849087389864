/**
 * Created by thiago on 21/11/16.
 */
angular.module('appSite').factory('depoimentoApiService', function($http, config){
    var _getDepoimentos = function(){
        return $http.get(config.baseUrl + '/api/depoimento/')
    };
    var _salveDepoimento = function(depoimento){
        return $http.post(config.baseUrl + '/api/depoimento/', depoimento)
    };
    return {
        getDepoimentos: _getDepoimentos,
        salveDepoimento: _salveDepoimento
    }
});