/**
 * Created by thiago on 14/10/16.
 */
// Todo: Colocar consumo fixo no rootScope para melhorar performace

angular.module('appSite').config(function ($routeProvider, $locationProvider) {
    $routeProvider.when("/", {
        templateUrl: "view/meus-dados.html",
        authorize: true
    });
    $routeProvider.when("/novo-doador", {
        templateUrl: "view/cadastro-doador.html",
        controller: "cadastroDoadorCtrl",
        resolve: {},
        authorize: true
    });

    $routeProvider.when("/solicitacaoDoacao", {
        templateUrl: "view/solicitacao-doacao.html",
        controller: "solicitacaoDoacaoCtrl",
        resolve: {},
        authorize: true
    });

    $routeProvider.when("/meus-dados", {
        templateUrl: "view/meus-dados.html",
        controller: "cadastroDoadorCtrl",
        resolve: {},
        authorize: true
    });

    $routeProvider.when("/registrar-doacao", {
        templateUrl: "view/registrar-doacao.html",
        controller: "registroDoacaoCtrl",
        resolve: {
            params: function($route){
                return $route.current.params
            }
        },
        authorize: true
    });

    $routeProvider.when("/registrar-doacao/:id", {
        templateUrl: "view/registrar-doacao.html",
        controller: "registroDoacaoCtrl",
        resolve: {
            params: function($route){
                return $route.current.params
            }
        },
        authorize: true
    });

    $routeProvider.when("/minhas-doacoes", {
        templateUrl: "view/minhas-doacoes.html",
        controller: "minhasDoacoesCtrl",
        resolve: {
            doacoes: function (registroDoacaoApiService) {
                return registroDoacaoApiService.getRegistroDoacao()
            },
            cidadesDoacao: function (cidadeDoacaoApiService) {
                return cidadeDoacaoApiService.getCidadeDoacao();
            },
            hemocentros: function (hemocentroApiService) {
                return hemocentroApiService.getHemocentro()
            }
        },
        authorize: true
    });

    $routeProvider.when("/solicitacoes-doacoes", {
        templateUrl: "view/solicitacoes-doacoes.html",
        controller: "solicitacaoDoacaoCtrl",
        resolve: {
            solicitacoes: function (solicitacaoDoacaoApiService) {
                return solicitacaoDoacaoApiService.getSolicitacoesDoacao()
            },
            hemocentros: function (hemocentroApiService) {
                return hemocentroApiService.getHemocentro()
            },
            hemocentroUnidades: function (hemocentroUnidadeApiService) {
                return hemocentroUnidadeApiService.getHemocentroUnidade()
            },
            tipos_sanguineos: function (tipoSanguineoApiService) {
                return tipoSanguineoApiService.getTipoSanguineo()
            },
            solicitacoesAtendidas: function(solicitacaoDoacaoApiService){
                return solicitacaoDoacaoApiService.getSolicitacoAtendida()
            }
        },
        authorize: true
    });

    $routeProvider.otherwise({redirectTo: "/meus-dados"})
});