/**
 * Created by thiago on 02/11/16.
 */

angular.module('appSite').factory('registroDoacaoApiService', function ($http, config) {
    var _getRegistroDoacao = function (doacao_id) {
        if (doacao_id){
            return $http.get(config.baseUrl + '/api/registrodoacao/' + doacao_id +'/')

        }else{
            return $http.get(config.baseUrl + '/api/registrodoacao/')
        }

    };

    var _getRegistroDoacaoPagina = function(pagina){
        return $http.get(config.baseUrl + '/api/registrodoacao/?page_size=' + pagina)
    };

    var _saveRegistroDoacao = function (data) {
        if (data.id){
            return $http.put(config.baseUrl + '/api/registrodoacao/' + data.id +'/', data)
        }else {
            return $http.post(config.baseUrl + '/api/registrodoacao/', data)
        }
    };

    var _removeRegistroDoacao = function (doacao_id) {
        return $http.delete(config.baseUrl + '/api/registrodoacao/' + doacao_id +'/')
    };

    return {
        getRegistroDoacao: _getRegistroDoacao,
        getRegistroDoacaoPagina: _getRegistroDoacaoPagina,
        saveRegistroDoacao: _saveRegistroDoacao,
        removeRegistroDoacao: _removeRegistroDoacao
    }
});