/**
 * Created by thiago on 25/11/16.
 */
angular.module('appSite').controller('minhasDoacoesCtrl',
    ["$scope", "registroDoacaoApiService", "doacoes", "cidadesDoacao", "hemocentros", "$uibModal", "campanhaApiService", function ($scope, registroDoacaoApiService, doacoes, cidadesDoacao, hemocentros, $uibModal, campanhaApiService) {

        // Tratamento dos dados pra tela

        var formatDoacao = function () {
            $scope.doacoes.forEach(function (doacao) {
                doacao.cidade = $scope.cidadesDoacao.filter(function (c) {
                    return c.id == doacao.cidade
                })[0];
                doacao.hemocentro = $scope.hemocentros.filter(function (hemocentro) {
                    return hemocentro.id == doacao.hemocentro;
                })[0];
                $scope.doou_medula = doacao.doou_medula;
                doacao.tipo_doacao = '';
                if (doacao.doou_medula) {
                    doacao.doou_medula = 'Sim';
                    doacao.tipo_doacao = 'Medula';
                } else {
                    doacao.doou_medula = 'Não';
                    doacao.tipo_doacao = 'Sangue';
                }
                var ano = doacao.data_doacao.substring(0, 4);
                var mes = doacao.data_doacao.substring(5, 7);
                var dia = doacao.data_doacao.substring(8, 10);
                doacao.data_doacao = dia + '/' + mes + '/' + ano;
                if (doacao.campanha) {
                    campanhaApiService.getCampanha(doacao.campanha).then(function (data) {
                        doacao.campanhaTitulo = data.titulo;
                        if (doacao.sub_campanha) {
                            doacao.subCampanhaTitulo = data.sub_campanhas.filter(function (s_campanha) {
                                return s_campanha.id == doacao.sub_campanha;
                            })[0].titulo;
                        } else {
                            doacao.subCampanhaTitulo = 'Não participou de um grupo';
                        }
                    });
                } else {
                    doacao.campanhaTitulo = 'Não participou de campanha';
                    doacao.subCampanhaTitulo = 'Não participou de um grupo';

                }
            });
        };


        $scope.cidadesDoacao = cidadesDoacao.data;
        $scope.hemocentros = hemocentros.data;
        $scope.doacoes = doacoes.data.results;
        formatDoacao($scope.doacoes);

        // Atributos de paginacao
        $scope.totalItems = doacoes.data.count;
        $scope.itemsPerPage = 5;
        $scope.currentPage = 1;

        // Metodo que requisita pagina no servidor
        $scope.pageChanged = function () {
            registroDoacaoApiService.getRegistroDoacaoPagina($scope.currentPage).
                success(function (data) {
                    $scope.doacoes = data.results;
                    formatDoacao($scope.doacoes);
                })
        };

        // Exclui um registro de doacao
        $scope.removerRegistro = function (id) {
            var modalInstance = $uibModal.open({
                animation: true,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: 'modalExcluirRegistro.html',
                controller: 'modalExcluirRegistro',
                controllerAs: '$ctrl'

            });
            modalInstance.result.then(function (option) {
                $scope.doacoes = $scope.doacoes.filter(function (doacao) {
                    if (doacao.id != id) return doacao
                });
                registroDoacaoApiService.removeRegistroDoacao(id);

            }, function () {

            });
        }
    }]);

angular.module('appSite').controller('modalExcluirRegistro',
    ["$uibModalInstance", "$scope", function ($uibModalInstance, $scope) {
        $scope.ok = function () {
            $uibModalInstance.close('ok');
        };
        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel')
        };
    }]);