/**
 * Created by thiago on 12/11/16.
 */
angular.module('appSite').directive('uiCompareTo', function () {
    return {
        require: 'ngModel',
        scope: {
            otherModelValue: "=uiCompareTo"
        },
        link: function (scope, element, attributes, ngModel) {
            ngModel.$validators.uiCompareTo = function (modelValue) {
                return modelValue == scope.otherModelValue;
            };

            scope.$watch("otherModelValue", function () {
                ngModel.$validate();
            });
        }
    }
});