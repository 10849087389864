/**
 * Created by thiago on 01/02/17.
 */
angular.module('appSite').factory('campanhaApiService', function ($http, config) {
    var _getCampanha = function (campanha_id) {
        if (campanha_id) {
            return $http.get(config.baseUrl + '/api/campanha/' + campanha_id +'/').then(function (response) {
                return response.data;
            })
        } else {
            return $http.get(config.baseUrl + '/api/campanha/').then(function (response) {
                return response.data;
            })
        }
    };
    return {
        getCampanha: _getCampanha
    }
});