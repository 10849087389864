/**
 * Created by thiago on 24/01/17.
 */
angular.module("appSite").directive('uiCard', function () {
    return {
        templateUrl: "view/card.html",
        replace: true,
        restrict: "AE",
        scope: {
            img: "@",
            titulo: "@",
            descricao: "@",
            resultado: "@",
            modal: "@"
        },
        link: link
    }

    function link(scope, element, attrs) {
        if(scope.modal.indexOf('#') === -1) {
            element[0].querySelector('#btn-campanha-detalhes').setAttribute('target', '_blank')
            element[0].querySelector('#btn-campanha-detalhes-img').setAttribute('target', '_blank')
        }
    }
});