/**
 * Created by thiago on 01/10/16.
 */
angular.module('appSite').factory('cadastroDoadorApiService', function ($http, config) {
    var _getTotalDoadores = function() {
        return $http.get(config.baseUrl + '/doadores/total/').then(function(response) {
            return response.data
        })
    }
    var _saveCadastroDoador = function (doador) {
        if (doador.id){
            return $http.put(config.baseUrl + '/api/doadores/' + doador.id + '/', doador)
        }else{
            return $http.post(config.baseUrl + '/api/doadores/?format=json', doador)
        }
    };

    var _getCadastroDoador = function (id_doador) {
        return $http.get(config.baseUrl + '/api/doadores/' + id_doador + '/')
    };

    var _getCidadeDoacao = function(){
        return $http.get(config.baseUrl + '/api/cidadedoacao?format=json')
    };

    return {
        saveCadastroDoador: _saveCadastroDoador,
        getCadastroDoador: _getCadastroDoador,
        getCidadeDoacao: _getCidadeDoacao, 
        getTotalDoadores: _getTotalDoadores
    }
});
