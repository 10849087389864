/**
 * Created by thiago on 24/01/17.
 */
angular.module("appSite").directive('uiModal', function () {
    return {
        templateUrl: "view/modal.html",
        replace: true,
        restrict: "AE",
        scope: {
            modal: '@',
            titulo: '@',
            img: '@',
            subtitulo: '@',
            descricao: '@',
            descricaofinal: '@',
            resultado: '@'

        },
        transclude: true,
        link: function (scope, elem, attrs) {                    
            setTimeout(
                function() {
                    $('#'+attrs.modal).on('show.bs.modal', function() {
                        $('.campanha-container.bg-light-gray2').css('position', 'relative');
                        $('.campanha-container.bg-light-gray2').css('z-index', '7000');
                        console.debug('alterado z-index');
                    })
                    $('#'+attrs.modal).on('hide.bs.modal', function() {
                        $('.campanha-container.bg-light-gray2').css('position', '');
                        $('.campanha-container.bg-light-gray2').css('z-index', '');
                        console.debug('alterado z-index');
                    })                    
                }, 500)
            if (attrs.subcampanhas){
                scope.sub_campanhas = angular.fromJson(attrs.subcampanhas);
            }
        }
    }
});