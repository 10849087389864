/**
 * Created by thiago on 28/11/16.
 */
angular.module('appSite').factory('lembrarSenhaApiService', function ($http, config) {
    var _lembrarSenha = function (usuario) {
        return $http.post(config.baseUrl + '/rest-auth/password/reset/', usuario)
    };
    return {
        lembrarSenha: _lembrarSenha
    }
});