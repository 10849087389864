/**
 * Created by thiago on 01/02/17.
 */
angular.module('appSite').controller('campanhaCtrl', ["$scope", "campanhaApiService", function ($scope, campanhaApiService) {
    $scope.campanhas = [];
    campanhaApiService.getCampanha().then(function (data) {
        $scope.campanhas = data;
        $scope.campanhas.forEach(function(campanha){
            if(campanha.slug_campanha){
                campanha.url = '/campanhas/' + campanha.slug_campanha
            }
            else{
                campanha.url = '#campanha_' + campanha.id
            }
            //por causa da campanha Pratique doar sangue
        	if (campanha.id==13) {
        		campanha.registros_doacao += 23
        	}
        })
    })
}]);