/**
 * Created by thiago on 15/10/16.
 */
angular.module('appSite').factory('hemocentroApiService', function ($http, config) {
    var _getHemocentro = function () {
        return $http.get(config.baseUrl + '/api/hemocentros/?format=json')
    };
    return {
        getHemocentro: _getHemocentro
    }
});