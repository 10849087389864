/**
 * Created by thiago on 15/10/16.
 */
angular.module('appSite').factory('solicitacaoDoacaoApiService', function($http, config){
    var _getSolicitacaoDoacao = function (id_solicitacao) {
        return $http.get(config.baseUrl + '/api/solicitacoesdoacao/' + id_solicitacao + '/')
    };

    var _getSolicitacoesDoacao = function (){
        return $http.get(config.baseUrl + '/api/solicitacoesdoacao/')
    };

    var _getSolicitacaoDoacaoPagina = function (pagina) {
        return $http.get(config.baseUrl + '/api/solicitacoesdoacao/?page_size=' + pagina)
    };

    var _getSolicitacoAtendida = function(){
        return $http.get(config.baseUrl + '/api/solicitacaoatendida/?format=json')
    };

    var _saveSolicitacaoDocao = function(solicitacaoDoacao){
        return $http.post(config.baseUrl + '/api/solicitacoesdoacao/?format=json',
            solicitacaoDoacao)
    };

    var _saveAtenderSolicitacao = function(solicitacaoAtendida){
        return $http.post(config.baseUrl + '/api/solicitacaoatendida/?format=json',
            solicitacaoAtendida)
    };

    return {
        getSolicitacaoDoacao: _getSolicitacaoDoacao,
        getSolicitacoesDoacao: _getSolicitacoesDoacao,
        getSolicitacaoDoacaoPagina: _getSolicitacaoDoacaoPagina,
        getSolicitacoAtendida: _getSolicitacoAtendida,
        saveSolicitacaoDocao: _saveSolicitacaoDocao,
        saveAtenderSolicitacao: _saveAtenderSolicitacao
    }
});