/**
 * Created by thiago on 19/11/16.
 */

angular.module('appSite').factory('contatoApiService', function ($http, config) {
    var _salvarContato = function (contato) {
        return $http.post(config.baseUrl + '/api/contato/', contato)
    };

    return {
        salvarContato: _salvarContato
    }
});