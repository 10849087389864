/**
 * Created by thiago on 03/09/16.
 */

angular.module('appSite', ['ngRoute', 'ui.bootstrap', 'ui.mask', 'multipleSelect', 'ngMap',
  'ngAnimate', 'ngStorage', 'cgBusy', 'isteven-multi-select'])
  .config(['$httpProvider',
    function ($httpProvider) {
      /* csrf */
      $httpProvider.defaults.xsrfHeaderName = 'X-CSRFToken';
      $httpProvider.defaults.xsrfCookieName = 'csrftoken';
    }]).run(function ($rootScope, $location, AuthService, locaisConfig, hemocentroApiService) {

      // Implementado aqui pra fazer login automatico ao cadastrar doador
      $rootScope.error_to_login = {};
      $rootScope.logar = function (login) {
        $rootScope.error_to_login = {}
        AuthService.signin(login).success(function (data) {
          AuthService.setToken(data.token);
          AuthService.setUser(data.user_id);
          window.location.assign('administracao.html')
          $rootScope.login_error = {};
        }).error(function (data) {
          console.log(data);
          $rootScope.error_to_login = data;
        })
      };

      //Hemocentros
      hemocentroApiService.getHemocentro().success(function (data) {
        $rootScope.hemocentros = data;
      });

      // Estados
      $rootScope.estados = locaisConfig.locais.estados;
      $rootScope.$on('$routeChangeStart', function (event, next, current) {
        if (next && next.authorize) {
          if (!AuthService.getToken()) {
            $rootScope.$evalAsync(function () {
              $location.path('/');
            })
          }
        }
      });
    });

require('androidConfig');
require('cidadesApiService');
require('cadastroDoadorCtrl');
require('solicitarDoacaoCtrl');
require('ondeDoarCtrl');
require('loginCtrl');
require('contatoCtrl');
require('campanhaCtrl');
require('depoimentoCtrl');
require('registroDoacaoCtrl');
require('solicitacaoDoacaoCtrl');
require('minhasDoacoesCtrl');
require('cadastroDoadorApiService');
require('tipoSanguineoApiService');
require('solicitacaoDoacaoApiService');
require('hemocentroUnidadeApiService');
require('hemocentroApiService');
require('cidadeDoacaoApiService');
require('authService');
require('registroDoacaoApiService');
require('contatoApiService');
require('depoimentoApiService');
require('lembrarSenhaApiService');
require('campanhaApiService');
require('configValue');
require('cidadesConfig');
require('authInterceptor');
require('uiCompareTo');
require('uiIsEmpty');
require('uiCardDirective');
require('uiModalDirective');
require('routeConfig');