/**
 * Created by thiago on 15/10/16.
 */
angular.module('appSite').controller('solicitacaoDoacaoCtrl',
    ["$scope", "$http", "$location", "solicitacaoDoacaoApiService", "tipoSanguineoApiService", "hemocentroUnidadeApiService", "hemocentroApiService", "$uibModal", "solicitacoes", "hemocentros", "hemocentroUnidades", "tipos_sanguineos", "AuthService", "solicitacoesAtendidas", "$rootScope", function ($scope, $http, $location, solicitacaoDoacaoApiService, tipoSanguineoApiService,
              hemocentroUnidadeApiService, hemocentroApiService, $uibModal, solicitacoes,
              hemocentros, hemocentroUnidades, tipos_sanguineos, AuthService,
              solicitacoesAtendidas, $rootScope) {

        $scope.open = function (size, parentSelector) {
            var parentElem = parentSelector ?
                angular.element($document[0].querySelector('.modal-demo ' + parentSelector)) : undefined;
            $scope.modalInstance = $uibModal.open({
                animation: true,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: 'modalAtenderSolicitacao.html',
                controller: 'modalAtenderSolicitacao',
                controllerAs: '$ctrl',
                size: size,
                appendTo: parentElem,
                resolve: {solicitacao_atender: $scope.solicitacao_atender,
                    solicitacao:$scope.solicitacao}
            });
        };

        $scope.solicitacoes = solicitacoes.data;
        $rootScope.solicitacoesAtendidas = solicitacoesAtendidas.data;
        $scope.totalItems = $scope.solicitacoes.count;
        $scope.itemsPerPage = 5;
        $scope.currentPage = 1;
        $scope.hemocentros = hemocentros.data;
        $scope.hemocentroUnidades = hemocentroUnidades.data;
        $scope.tipos_sanguineos = tipos_sanguineos.data;

        $scope.pageChanged = function () {
            solicitacaoDoacaoApiService.getSolicitacaoDoacaoPagina($scope.currentPage).
                success(function (data) {
                    $scope.solicitacoes = data;
                })
        };

        $scope.getDataSolicitacao = function(data_solicitacao){
            data = new Date(data_solicitacao);
            return (data.getDate() < 10 ? '0' : '') + (data.getDate() + 1) + "/" + (data.getMonth() < 10 ? '0' : '') +
            (data.getMonth() + 1) + '/' + data.getFullYear();
        }


        $scope.getTipoDoacao = function (tipoDoacao) {
            if (tipoDoacao == 'S') {
                return 'Sangue'
            } else {
                return 'Medula'
            }
        };

        $scope.getTipoSanguineo = function (tipoSanguineoId) {
            return $scope.tipos_sanguineos.filter(function (tipo) {
                if (tipo.id == tipoSanguineoId) return tipo
            })[0].tipo;
        };

        $scope.getHemocentroNome = function (locais) {
            if (locais.length > 0) {
                return $scope.hemocentros.filter(function (hemocentroNomeCadastro) {
                    if (hemocentroNomeCadastro.id == locais[0].hemocentro) return hemocentroNomeCadastro
                })[0].nome;
            } else {
                return "";
            }
        };

        $scope.getHemocentrosUnidades = function (locais) {
            var h = "";
            locais.forEach(function (hemocentro) {
                var hemocentroUnidade = $scope.hemocentroUnidades.filter(function (hemocentroUnidade) {
                    if (hemocentroUnidade.id == hemocentro.hemocentro_unidade) return hemocentroUnidade
                })[0].nome_unidade;
                h += hemocentroUnidade + ', '
            });

            return h;
        };

        $scope.atenderSolicitacao = function (solicitacao) {
            $scope.solicitacao = solicitacao;
            $scope.solicitacao_atender = {
                solicitacao: solicitacao.id,
                doador: AuthService.getUser()
            };
            $scope.open();
        };

        $scope.solicitacaoAtendida = function (solicitacao) {
            var solicitacaoAtendida = $rootScope.solicitacoesAtendidas.filter(function (s) {
                if (s.solicitacao == solicitacao.id) {
                    return solicitacao
                }
            });
            return solicitacaoAtendida.length > 0;
        }
    }]);


angular.module('appSite').controller('modalAtenderSolicitacao',
    ["$uibModalInstance", "$scope", "solicitacaoDoacaoApiService", "$rootScope", function ($uibModalInstance, $scope, solicitacaoDoacaoApiService, $rootScope) {
        $scope.solicitacao_atender = $scope.$resolve.solicitacao_atender;
        $scope.solicitacao = $scope.$resolve.solicitacao;
        $scope.ok = function () {
            solicitacaoDoacaoApiService.saveAtenderSolicitacao($scope.solicitacao_atender).
                success(function (data) {
                    $scope.solicitacao.quantidade_atendida = data.quantidade_atendida_momento;
                    $rootScope.solicitacoesAtendidas.push(data);
                });
            $uibModalInstance.close();
        };

        $scope.cancelar = function(){
            $uibModalInstance.close();
        }
    }]);