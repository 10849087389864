/**
 * Created by thiago on 19/09/16.
 */

angular.module('appSite').controller('cadastroDoadorCtrl',
  ["$scope", "$http", "$location", "cadastroDoadorApiService", "tipoSanguineoApiService", "cidadeDoacaoApiService", "uibDateParser", "AuthService", "$uibModal", "registroDoacaoApiService", "hemocentroApiService", "$rootScope", "cidadeApiService", "$document", function ($scope, $http, $location, cadastroDoadorApiService, tipoSanguineoApiService,
    cidadeDoacaoApiService, uibDateParser, AuthService, $uibModal,
    registroDoacaoApiService, hemocentroApiService, $rootScope, cidadeApiService,
    $document) {

    //Total de doadoares

    $scope.total_doadores = null;
    cadastroDoadorApiService.getTotalDoadores().then(function (data) {
      $scope.total_doadores = data.total_doadores;
    })

    //Doador
    $scope.doador = { cidades: [] };

    // Tipos Sanguineos
    $scope.tipos_sanguineos = [];

    //Cidades doacao
    $scope.cidadesDoEstadoDoacao = [];
    $scope.cidadesDoacao = [];

    // cidades
    $scope.estado_onde_doa = false;
    $scope.estados = angular.copy($rootScope.estados);
    $scope.cidades = [];
    $scope.getCidades = function (estado, manterCidade) {
      if (!manterCidade) {
        $scope.doador.cidade = '';
        $scope.cidadeSelecionada = [];
        $scope.cidadesEscolhidas = [];
        delete $scope.doador.cidades;
      }
      $scope.doador.estado = estado.sigla;
      $scope.cidades = estado.cidades;
      $scope.getCidadesParaDoacao(estado.sigla);
    };

    $scope.getCidadesParaDoacao = function (estado) {
      $scope.estado_onde_doa = estado;
      $scope.cidadesDoEstadoDoacao = $scope.cidadesDoacao.filter(function (cidade) {
        if (cidade.estado == estado) {
          return cidade;
        }
      });
    };

    // Tratamento modal
    $scope.open = function (size, parentSelector) {
      var parentElem = parentSelector ?
        angular.element($document[0].querySelector('.modal-demo ' + parentSelector)) : undefined;
      $scope.modalInstance = $uibModal.open({
        animation: true,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        templateUrl: 'modalSucesso.html',
        controller: 'modalInstance',
        controllerAs: '$ctrl',
        size: size,
        appendTo: parentElem,
        resolve: { 'doador': $scope.doador }
      });
    };

    $scope.cidadesSeleciondas = [];
    $scope.cidadesEscolhidas = [];
    $scope.doador = {};
    $scope.hemocentros = {};
    $scope.error = {}
    // Retorna todos os hemocentros
    var getHemocentros = function () {
      hemocentroApiService.getHemocentro().success(function (data) {
        $scope.hemocentros = data
      })
    };
    getHemocentros();

    $scope.doadores = [];

    var getDoadores = function () {
      cadastroDoadorApiService.getCadastroDoador().success(function (data) {
        $scope.doadores = data;
      })
    };
    getDoadores();

    var getTiposSanguineos = function () {
      tipoSanguineoApiService.getTipoSanguineo().success(function (data) {
        $scope.tipos_sanguineos = data.filter(function (tipo) {
          if (tipo.visivel_somente_solicitacao != true) {
            return tipo;
          }
        });
      })
    };

    var getCidadeDoacao = function () {
      cidadeDoacaoApiService.getCidadeDoacao().success(function (data) {
        $scope.cidadesDoacao = data;
        $scope.buscar(AuthService.getUser());
      })
    };

    $scope.isInvalidForm = function () {
      $scope.modalInstance = $uibModal.open({
        animation: true,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        templateUrl: 'modalAtencao.html',
        controller: 'modalInstanceAtencao',
        controllerAs: '$ctrl'
      });
      return;
    };

    $scope.salvar = function (doador) {
      if (doador.email) {
        doador.username = doador.email;
      }
      doador.doacoes = [];
      if ($scope.checkCidadeOndeMora(doador.cidade)) {
        doador.cidade = undefined;
        $scope.cadastroDoador.$valid = false;
        $scope.cadastroDoador.doador_cidade_onde_mora.$setValidity('required', false);
      }
      if (!$scope.cadastroDoador.$valid) {
        angular.forEach($scope.cadastroDoador.$error, function (values, key) {
          var cont = 0;
          values.forEach(function (input) {
            if (!input.$dirty) {
              input.$dirty = true;
            }
            if (cont == 0) {
              window.location.href = "#novoDoador";
              document.getElementById(input.$name).focus();
              cont++;
            }
          });
        });
        return $scope.isInvalidForm()
      }
      cadastroDoadorApiService.saveCadastroDoador(doador).success(function (data) {
        $scope.cidadesEscolhidas = [];
        document.getElementById('novoDoador').style.display = "none";
        $scope.open(); // Abre modal de sucesso
        login = {
          'username': doador.email,
          'password': doador.password
        }
        $rootScope.logar(login);
        delete $scope.doador;
        $scope.cadastroDoador.$setPristine()

      }).error(function (data) {
        if (data.username) {
          $scope.doador.emailExistente = true;
          document.getElementById('doador_nome').focus();
        }
        if (data.data_nascimento) {
          $scope.error.data_nascimento = data.data_nascimento
          document.getElementById('doador_nome').focus();
          $uibModal.open({
            animation: true,
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl: 'view/alert.html',
            controller: 'alertInstance',
            controllerAs: '$ctrl',
            resolve: {
              message: function () {
                return data.data_nascimento[0]
              }
            }
          });

        }
      })
    };

    $scope.checkCidadeOndeMora = function (cidade) {
      var x = $scope.cidades.filter(function (c) {
        if (c == cidade) {
          return c
        }
      });
      if (x.length == 0) {
        return true
      } else {
        return false
      }
    };

    $scope.salvarAlteracao = function (doador) {
      if (doador.email) {
        doador.username = doador.email;
      }
      doador.estado = doador.estado || 'MG';
      if (!doador.cidade) {
        $scope.cadastroDoador.cidades_doador.$dirty = true;
        return
      }
      cadastroDoadorApiService.saveCadastroDoador(doador).success(function (data) {
        $scope.isOpen = false;
        $scope.buscar(doador.id);
      }).error(function (e) {
        if (e.data_nascimento) {
          $scope.error.data_nascimento = e.data_nascimento
          $uibModal.open({
            animation: true,
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl: 'view/alert.html',
            controller: 'alertInstance',
            controllerAs: '$ctrl',
            resolve: {
              message: function () {
                return e.data_nascimento[0]
              }
            }
          });

        }
      })
    };

    $scope.addCidade = function (cidade) {
      var cidadeJaInformada = $scope.cidadesEscolhidas.filter(function (cidadeEscolhida) {
        if (cidade === cidadeEscolhida) return cidadeEscolhida
      });
      if (cidadeJaInformada.length < 1) {
        $scope.cidadesEscolhidas.push(cidade);
      }
      delete $scope.cidadeSelecionada;
    };

    $scope.onSelect = function ($item, $model, $label) {
      if ($scope.doador.cidades) {
        $scope.doador.cidades.push($model);
      } else {
        $scope.doador.cidades = [$model]
      }
      $scope.addCidade($item);
      $scope.cidadeSelecionada = undefined;
    };

    $scope.excluirCidade = function (cidadeDeletar) {
      $scope.cidadesEscolhidas = $scope.cidadesEscolhidas.filter(function (cidade) {
        if (cidadeDeletar.id != cidade.id) return cidade;
      });
      $scope.doador.cidades = $scope.doador.cidades.filter(function (cidade) {
        if (cidadeDeletar.id != cidade) return cidade;
      });
      if ($scope.doador.cidades.length == 0) {
        delete $scope.doador.cidades;
      }
    };

    $scope.buscar = function (id_doador) {
      cadastroDoadorApiService.getCadastroDoador(id_doador).success(function (data) {
        $scope.doador = data;
        $scope.tipos_sanguineos.filter(function (tipo) {
          if ($scope.doador.tipo_sanguineo == tipo.id) {
            $scope.tipo_sanguineo = tipo.tipo;
          }
        });
        $scope.doador.data_nascimento = new Date($scope.doador.data_nascimento);

        if ($scope.doador.data_ultima_doacao) {
          ano = $scope.doador.data_ultima_doacao.substring(0, 4);
          mes = $scope.doador.data_ultima_doacao.substring(5, 7);
          dia = $scope.doador.data_ultima_doacao.substring(8, 10);
          $scope.data_ultima_doacao = dia + '/' + mes + '/' + ano;
        }
        else {
          $scope.data_ultima_doacao = 'Não existe registro de doações'
        }

        $scope.doador_medula = "Não";
        if ($scope.doador.doa_medula) {
          $scope.doador_medula = "Sim"
        }

        $scope.sexo_doador = "Feminino";
        if ($scope.doador.sexo == 'M') {
          $scope.sexo_doador = 'Masculino'
        }
        $scope.cidadesEscolhidas = [];
        $scope.doador.cidades.forEach(function (cidade) {
          $scope.cidadesDoacao.filter(function (cidadeDoacao) {
            if (cidadeDoacao.id == cidade) {
              $scope.addCidade(cidadeDoacao);
            }
          })
        });

        if ($scope.data_ultima_doacao == 'Não existe registro de doações') {
          $scope.data_proxima_doacao = 'Você já está apto(a) a doar';
          $rootScope.apto_doar = true;
        }
        else {
          var data_prox_doacao = new Date($scope.doador.data_ultima_doacao);
          if ($scope.doador.sexo == 'M') {
            data_prox_doacao.setMonth(data_prox_doacao.getMonth() + 2);
          }
          else {
            data_prox_doacao.setMonth(data_prox_doacao.getMonth() + 3);
          }

          var hoje = new Date();

          if (data_prox_doacao > hoje) {
            $rootScope.apto_doar = false;
            $scope.data_proxima_doacao = (data_prox_doacao.getDate() < 10 ? '0' : '') + data_prox_doacao.getDate() + '/' +
              (data_prox_doacao.getMonth() < 10 ? '0' : '') + (data_prox_doacao.getMonth() + 1)
              + '/' + data_prox_doacao.getFullYear();
          }
          else {
            $rootScope.apto_doar = true;
            $scope.data_proxima_doacao = 'Desde  ' + (data_prox_doacao.getDate() < 10 ? '0' : '') + data_prox_doacao.getDate()
              + '/' + (data_prox_doacao.getMonth() < 10 ? '0' : '') + (data_prox_doacao.getMonth() + 1)
              + '/' + data_prox_doacao.getFullYear() + ' você está apto(a) a doar';
          }
        }
      })
    };

    $scope.editar = function () {
      $scope.isOpen = true;
      $scope.estados.filter(function (estado) {
        if (estado.sigla == $scope.doador.estado) {
          $scope.getCidades(estado, true)

        }
      })
    };


    $scope.format = 'ddMMyyyy';
    $scope.format_original = 'yyyy-MM-dd';

    getTiposSanguineos();
    getCidadeDoacao();

    $scope.sortDataDoacao = function (doacao) {
      var t = new Date(getDataFormat(doacao.data_doacao));
      return new Date(getDataFormat(doacao.data_doacao));
    };

    var getDataFormat = function (data) {
      var ano = data.substring(6, 12);
      var mes = data.substring(3, 5);
      var dia = data.substring(0, 2);
      return ano + '-' + mes + '-' + dia;
    };

    $scope.checkEstado = function (isOpen, estado_onde_doa) {
      if (isOpen && !estado_onde_doa) {
        $uibModal.open({
          animation: true,
          ariaLabelledBy: 'modal-title',
          ariaDescribedBy: 'modal-body',
          templateUrl: 'modalCidadeAtencao.html',
          controller: 'modalInstanceAtencao',
          controllerAs: '$ctrl'

        });
      }
    }
  }]
);

angular.module('appSite').controller('modalInstance', ["$uibModalInstance", "$scope", function ($uibModalInstance, $scope) {
  $scope.doador = $scope.$resolve.doador;
  $scope.ok = function () {
    $uibModalInstance.close();
  };
}]);

angular.module('appSite').controller('modalInstanceAtencao', ["$uibModalInstance", "$scope", function ($uibModalInstance, $scope) {
  $scope.ok = function () {
    $uibModalInstance.close();
  };
}]);

angular.module('appSite').controller('alertInstance', ["$uibModalInstance", "$scope", "message", function (
  $uibModalInstance, $scope, message) {
  $scope.message = message;
  $scope.ok = function () {
    $uibModalInstance.close();
  };
}]);


