/**
 * Created by thiago on 27/12/16.
 */

angular.module('appSite').factory('cidadeApiService', function ($http) {
    var _getCidades = function () {
        return $http.get('http://www.partiudoarsangue.com.br/json/cidades.json')
    };
    return {
        getCidades: _getCidades
    }
});
